<template>
    <div id="scrollUp" title="Scroll To Top">
        <i class="fas fa-arrow-up"></i>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>