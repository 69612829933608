<template>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_1.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">Anna Sword</a></li>
                        <li><a href="#">Feb 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_2.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">Jassica William</a></li>
                        <li><a href="#">Feb 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_3.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">John Doe</a></li>
                        <li><a href="#">Mar 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_4.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">Anna Sword</a></li>
                        <li><a href="#">Feb 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_5.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">Jassica William</a></li>
                        <li><a href="#">Feb 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_6.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">John Doe</a></li>
                        <li><a href="#">Mar 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_7.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">Anna Sword</a></li>
                        <li><a href="#">Feb 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_8.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">Jassica William</a></li>
                        <li><a href="#">Feb 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <!-- Single Blog -->
            <div class="single-blog">
                <!-- Blog Thumb -->
                <div class="blog-thumb">
                    <a href="#"><img src="assets/img/blog_9.jpg" alt=""></a>
                </div>
                <!-- Blog Content -->
                <div class="blog-content">
                    <!-- Meta Info -->
                    <ul class="meta-info d-flex justify-content-between">
                        <li>By <a href="#">John Doe</a></li>
                        <li><a href="#">Mar 05, 2020</a></li>
                    </ul>
                    <!-- Blog Title -->
                    <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                    <a href="#" class="blog-btn mt-3">Read More</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>