<template>
    <section id="home" class="section welcome-area overflow-hidden d-flex align-items-center">
        <div class="container">
            <div class="row align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7">
                    <div class="welcome-intro">
                        <h1>We make the path to your digital presence</h1>
                        <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.</p>
                        <!-- Buttons -->
                        <div class="button-group">
                            <a href="#" class="btn btn-bordered">Work with Us</a>
                            <a href="#" class="btn btn-bordered d-none d-sm-inline-block">View Process</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>