<template>
    <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
            <div class="row align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7">
                    <div class="welcome-intro">
                        <h1 class="text-white"><span class="fw-4">Boost your business with</span><br> Custom email marketing</h1>
                        <p class="text-white-50 my-4">We redefine email marketing by creating bespoke campaigns that resonate with your audience. Our expert team meticulously crafts custom content and visually stunning designs to showcase your brand's uniqueness.</p>
                        <!-- Buttons -->
                        <div class="button-group">
                            <!-- <a href="#" class="btn btn-bordered-white active">Start a Project</a> -->
                            <a href="mailto:lazitskiy@gmail.com" class="btn btn-bordered-white d-none d-sm-inline-block" target="_blank">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5">
                    <!-- Welcome Thumb -->
                    <div class="welcome-thumb-wrapper mt-5 mt-md-0">
                        <span class="welcome-thumb-1">
                            <img class="d-block ml-auto" src="assets/images/email2.png" alt="">
                        </span>
                        <span class="welcome-thumb-2">
                            <!-- <img class="welcome-animation d-block" src="assets/img/welcome_thumb_12.png" alt="" style="width: 150px; height: 240px;"> -->
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>