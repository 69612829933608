<template>
    <section class="section content-area dark-bg ptb_150">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-lg-6">
                    <!-- Content Inner -->
                    <div class="content-inner text-center">
                        <!-- Section Heading -->
                        <div class="section-heading text-center mb-3">
                            <h2 class="text-white">We help to grow your business</h2>
                            <p class="text-white-50 d-none d-sm-block mt-4">We are catalysts for your business growth. With our dedicated efforts, we empower your journey to success, providing tailored solutions and unwavering support to drive your business forward.</p>
                            <p class="text-white-50 d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                        </div>
                        <!-- Content List -->
                        <ul class="content-list text-left">
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span class="text-white"><b>Online Presence</b><br>Establish a compelling online presence with our expert solutions.</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span class="text-white"><b>Marketing Stretagy</b><br>Create a custom marketing strategy to boost your business—enhancing brand visibility and driving conversions. Our tailored approach ensures success.</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span class="text-white"><b>Promote Local Sales</b><br>Boost local sales with targeted promotion strategies. Tailoring our approach to your unique market, we aim to enhance visibility and drive success.</span>
                                </div>
                            </li>
                        </ul>
                        <!-- <a href="#" class="btn btn-bordered-white mt-4">Learn More</a> -->
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Service Thumb -->
                    <div class="service-thumb mx-auto pt-4 pt-lg-0">
                        <img src="assets/images/jpg/growth1.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>