<template>
    <section class="section content-area ptb_150">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-lg-6">
                    <!-- Profile Circle Wrapper -->
                    <div class="profile-circle-wrapper circle-animation d-none d-sm-block">
                        <!-- Profile Inner -->
                        <div class="profile-inner">
                            <!-- Profile Circle -->
                            <div class="profile-circle circle-lg">
                                <span class="profile-icon icon-1">
                                    <img class="icon-1-img" src="assets/img/profile_icon_1.svg" alt="" />
                                </span>
                                <span class="profile-icon icon-2">
                                    <img class="icon-2-img" src="assets/img/profile_icon_2.svg" alt="" />
                                </span>
                                <span class="profile-icon icon-3">
                                    <img class="icon-3-img" src="assets/img/profile_icon_1.svg" alt="" />
                                </span>
                                <span class="profile-icon icon-4">
                                    <img class="icon-4-img" src="assets/img/profile_icon_2.svg" alt="" />
                                </span>
                            </div>

                            <!-- Profile Circle -->
                            <div class="profile-circle circle-md">
                                <span class="profile-icon icon-5">
                                    <img class="icon-5-img" src="assets/img/profile_icon_3.svg" alt="" />
                                </span>
                                <span class="profile-icon icon-6">
                                    <img class="icon-6-img" src="assets/img/profile_icon_3.svg" alt="" />
                                </span>
                                <span class="profile-icon icon-7">
                                    <img class="icon-7-img" src="assets/img/profile_icon_3.svg" alt="" />
                                </span>
                            </div>

                            <!-- Profile Circle -->
                            <div class="profile-circle circle-sm">
                                <span class="profile-icon icon-8">
                                    <img class="icon-8-img" src="assets/img/profile_icon_4.svg" alt="" />
                                </span>
                                <span class="profile-icon icon-9">
                                    <img class="icon-9-img" src="assets/img/profile_icon_4.svg" alt="" />
                                </span>
                            </div>
                        </div>
                        <img class="folder-img" src="assets/img/folders.png" alt="" />
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Content Inner -->
                    <div class="content-inner text-center pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                        <!-- Section Heading -->
                        <div class="section-heading text-center mb-3">
                            <h2>Work smarter,<br> not harder.</h2>
                            <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                            <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                        </div>
                        <!-- Content List -->
                        <ul class="content-list text-left">
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-2"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>Digital Agency &amp; Marketing</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-2"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>Planning To Startup</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-2"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>Content Management</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                                </div>
                            </li>
                        </ul>
                        <a href="#" class="btn btn-bordered mt-4">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>