<template>
    <section id="service" class="section service-area dark-bg ptb_150">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-white">We provide the best digital services</h2>
                        <p class="text-white-50 d-none d-sm-block mt-4">Empowering success through top-tier digital services. Our commitment to innovation ensures unmatched solutions for your digital needs.</p>
                        <p class="text-white-50 d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service media p-3">
                        <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                        <!-- Service Text -->
                        <div class="media-body ml-4">
                            <h3 class="text-white">Data Analytics</h3>
                            <p class="text-white-50 mt-2 mb-3">Unlock insights and drive decisions with our advanced data analytics solutions. </p>
                            <!-- <a class="service-btn" href="#">Learn More</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service media p-3">
                        <span class="flaticon-email color-5 icon-bg-5"></span>
                        <!-- Service Text -->
                        <div class="media-body ml-4">
                            <h3 class="text-white">Email Marketing</h3>
                            <p class="text-white-50 mt-2 mb-3">Elevate your outreach strategy with our email marketing solutions.</p>
                            <!-- <a class="service-btn" href="#">Learn More</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service media p-3">
                        <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                        <!-- Service Text -->
                        <div class="media-body ml-4">
                            <h3 class="text-white">Affiliate Marketing</h3>
                            <p class="text-white-50 mt-2 mb-3">Experience unparalleled growth through our strategic affiliate marketing services. </p>
                            <!-- <a class="service-btn" href="#">Learn More</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>