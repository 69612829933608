<template>
    <section class="section breadcrumb-area overlay-dark d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Breamcrumb Content -->
                    <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                        <h2 class="text-white text-uppercase mb-3">Our Team</h2>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="/">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item text-white active">Our Team</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>