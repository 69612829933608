<template>
    <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-10">
                    <div class="welcome-intro text-center mt-0 py-5">
                        <h1 class="text-white">The world is searching. <br>We'll help you get discovered.</h1>
                        <p class="text-white my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.</p>
                        <!-- Buttons -->
                        <div class="button-group">
                            <a href="#" class="btn btn-bordered-white">Contact Us</a>
                            <a href="#" class="btn btn-bordered-white d-none d-sm-inline-block">View Process</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>