<template>
    <footer class="section footer-area dark-bg">
        <!-- Footer Bottom -->
        <div class="footer-bottom dark-bg">
            <div class="text-center py-4">
                <div class="copyright-left text-white-50">
                    &copy; Copyrights 2024 Lezzetli solutions All rights reserved.
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>