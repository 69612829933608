<template>
    <section class="section service-area ptb_150">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service service-gallery m-0 overflow-hidden">
                        <!-- Service Thumb -->
                        <div class="service-thumb">
                            <a href="#"><img src="assets/img/case_studies_1.jpg" alt=""></a>
                        </div>
                        <!-- Service Content -->
                        <div class="service-content bg-white">
                            <a href="#">
                                <h3>Data Analytics</h3>
                            </a>
                            <p class="py-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            <a class="service-btn" href="#">Learn More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service service-gallery m-0 overflow-hidden">
                        <!-- Service Thumb -->
                        <div class="service-thumb">
                            <a href="#"><img src="assets/img/case_studies_2.jpg" alt=""></a>
                        </div>
                        <!-- Service Content -->
                        <div class="service-content bg-white">
                            <a href="#">
                                <h3>Website Growth</h3>
                            </a>
                            <p class="py-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            <a class="service-btn" href="#">Learn More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Service -->
                    <div class="single-service service-gallery m-0 overflow-hidden">
                        <!-- Service Thumb -->
                        <div class="service-thumb">
                            <a href="#"><img src="assets/img/case_studies_3.jpg" alt=""></a>
                        </div>
                        <!-- Service Content -->
                        <div class="service-content bg-white">
                            <a href="#">
                                <h3>Seo Ranking</h3>
                            </a>
                            <p class="py-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            <a class="service-btn" href="#">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>