<template>
    <section class="section case-studies ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_7.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="assets/img/case_studies_7.jpg" data-fancybox="images" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <i class="fas fa-search-plus fa-2x text-white"></i>
                                <h3 class="text-white mt-2 mb-3">Web Design</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_8.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="assets/img/case_studies_8.jpg" data-fancybox="images" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <i class="fas fa-search-plus fa-2x text-white"></i>
                                <h3 class="text-white mt-2 mb-3">App Development</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_9.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="assets/img/case_studies_9.jpg" data-fancybox="images" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <i class="fas fa-search-plus fa-2x text-white"></i>
                                <h3 class="text-white mt-2 mb-3">Data Analysis</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_10.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="assets/img/case_studies_10.jpg" data-fancybox="images" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <i class="fas fa-search-plus fa-2x text-white"></i>
                                <h3 class="text-white mt-2 mb-3">Content Management</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_11.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="assets/img/case_studies_11.jpg" data-fancybox="images" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <i class="fas fa-search-plus fa-2x text-white"></i>
                                <h3 class="text-white mt-2 mb-3">SEO Marketing</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_12.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="assets/img/case_studies_12.jpg" data-fancybox="images" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <i class="fas fa-search-plus fa-2x text-white"></i>
                                <h3 class="text-white mt-2 mb-3">Marketing Strategy</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti.</p>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <a href="#" class="btn btn-bordered mt-4">View More</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>