<template>
    <section class="section content-area bg-grey ptb_150">
        <!-- Shape Top -->
        <div class="shape shape-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-lg-6">
                    <!-- Content Inner -->
                    <div class="content-inner text-center">
                        <!-- Section Heading -->
                        <div class="section-heading text-center mb-3">
                            <h2>We help to grow your business.</h2>
                            <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                            <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                        </div>
                        <!-- Content List -->
                        <ul class="content-list text-left">
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>Online Presence</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>Marketing Stretagy</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>Promote Local Sales</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                                </div>
                            </li>
                        </ul>
                        <a href="#" class="btn btn-bordered mt-4">Learn More</a>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Service Thumb -->
                    <div class="service-thumb mx-auto pt-4 pt-lg-0">
                        <img src="assets/img/content_thumb.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape shape-bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>