<template>
    <div class="row">
        <div class="col-12">
            <!-- Pagination -->
            <ul class="pagination justify-content-center">
                <li class="disabled px-1">
                    <a href="#" aria-label="Previous">
                        <i class="fas fa-arrow-left"></i>
                    </a>
                </li>
                <li class="px-1"><a href="#">1</a></li>
                <li class="active px-1"><a href="#">2</a></li>
                <li class="px-1"><a href="#">3</a></li>
                <li>
                    <a href="#" aria-label="Next">
                        <i class="fas fa-arrow-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>