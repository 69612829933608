<template>
    <section id="portfolio" class="portfolio-area bg-gray overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_1.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Digital Marketing</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_2.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">App Development</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_3.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Data Analysis</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_4.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Content Management</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_5.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">SEO Marketing</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_6.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Marketing Strategy</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_3.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Data Analysis</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_1.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Digital Marketing</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Single Case Studies -->
                    <div class="single-case-studies">
                        <!-- Case Studies Thumb -->
                        <a href="#">
                            <img src="assets/img/case_studies_4.jpg" alt="">
                        </a>
                        <!-- Case Studies Overlay -->
                        <a href="#" class="case-studies-overlay">
                            <!-- Overlay Text -->
                            <span class="overlay-text text-center p-3">
                                <h3 class="text-white mb-3">Content Management</h3>
                                <p class="text-white">Lorem ipsum dolor sit amet, consectet ur adipisicing elit.</p>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <a href="#" class="btn btn-bordered mt-4">View More</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>